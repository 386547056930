.page {
    background-color: #fff;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    @include min-screen($d+150px) {
        background-image: url('../images/bg-generic.jpg');
    }

    &_location {
        @include min-screen($d+150px) {
            background-image: url('../images/bg-generic-2.jpg');
        }
    }

    &__container {
        padding-top: 40px;
        padding-bottom: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 79vh;
        min-height: calc(100vh - 122px);
    }

    &__content {
        text-align: center;
        @include min-screen($d+150px) {
            margin-right: 50%;
            transform: translateX(-40px);
        }

        .page_location & {
                @include min-screen($d+150px) {
                margin-right: 40%;
                transform: translateX(-40px);
            }
        }
    }

    &__title {
        color: $color_dark-blue;
        font-family: $font_bold;
        font-size: 18px;
        @include min-screen($tl) {
            font-size: 38px;
        }
    }

    &__logo1 {
        display: block;
        margin: 10px auto 15px;
        overflow: hidden;
        text-indent: -99em;
        width: 232px;
        height: 71px;
        background-image: url('../images/rrlogo.svg');
        background-repeat: no-repeat;
        background-size: cover;
        transform: translateX(10px);

        @include min-screen($tl) {
            margin: 15px auto 35px;
            width: 388px;
            height: 119px;
            transform: translateX(18px);
        }
    }

    &__logo3 {
        display: block;
        margin: 10px auto 15px;
        overflow: hidden;
        text-indent: -99em;
        width: 267px;
        height: 127px;
        background-image: url('../images/atlantic-logo.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        transform: translateX(10px);

        @include min-screen($tl) {
            margin: 15px auto 35px;
            transform: translateX(18px);
        }
    }

    &__logo2 {
        display: block;
        margin: 10px auto 40px;
        overflow: hidden;
        text-indent: -99em;
        width: 247px;
        height: 63px;
        background-image: url('../images/logo-freeway-insurance.svg');
        background-repeat: no-repeat;
        background-size: cover;

        @include min-screen($tl) {
            margin: 15px auto 35px;
            width: 429px;
            height: 110px;
        }
    }

    &__text {
        margin-bottom: 10px;
        font-size: 16px;
        @include min-screen($tl) {
            font-size: 20px;
            margin-bottom: 35px;
        }

        p {
            margin: 0 auto 10px;
            max-width: 688px;
        }
    }
}



.bold-text {
    font-family: $font_bold;

    &_blue {
        color: $color_blue;
    }
}

.features {
    margin: 20px auto 30px;
    max-width: 480px;
    @include min-screen($tl) {
        margin-top: 48px;
    }

    &__list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    &__icon {
        width: 70px;
        height: 70px;
        border: 5px solid #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        border-radius: 50%;
        margin: 0 auto;
        @include min-screen($tl) {
            width: 80px;
            height: 80px;
        }

        &_orange {
            background-color: $color_orange;
        }

        &_skyblue {
            background-color: $color_skyblue;
        }

        &_green {
            background-color: $color_green;
        }
    }

    &__text {
        font-size: 12px;
        margin-top: 15px;
        max-width: 134px;
        @include min-screen($tl) {
            font-size: 16px;
        }

        strong {
            display: block;
            font-family: $font_bold;
        }
    }
}