@mixin container() {
    max-width: $d;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
}
  
.container {
    @include container();
}

.icon {
    display: inline-block;
    line-height: 0.8;
    
    > svg {
        display: inline-block;
        font-size: inherit;
        width: 1em;
        height: 1em;
        overflow: visible;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
    }
}