.site-footer {
    background-color: $color_dark-blue;
    padding: 20px 0;
    @include min-screen($tl) {
        padding: 25px 0;
    }

    &__text {
        text-align: center;
        font-size: 14px;
        color: #fff;
        margin-bottom: 18px;
        @include min-screen($tl) {
            font-size: 18px;
            margin-bottom: 12px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin: 0;
        }
    }
}