// Fonts

@mixin font-family($font-name, $font-file, $font-weight: normal, $font-style: normal) {
    @font-face {
        font-family: $font-name;
        src: url('../fonts/'+$font-file+'.woff2') format('woff2'), url('../fonts/'+$font-file+'.woff') format('woff');
        font-weight: $font-weight;
        font-style: $font-style;
        font-display: fallback;
    }
}

@include font-family('OpenSans Regular', 'opensans-regular', -weight, -style);
@include font-family('OpenSans Bold', 'opensans-bold', 'bold', -style);