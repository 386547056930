// Clearfix
@mixin clearfix() {
    &::before, &::after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin placeholder($color) {
    &::-webkit-input-placeholder {
       color: $color;
       opacity: 1;
    }

    &:-moz-placeholder {
       color: $color;
       opacity: 1;
    }

    &::-moz-placeholder {
       color: $color;
       opacity: 1;
    }

    &:-ms-input-placeholder {
       color: $color;
       opacity: 1;
    }
}

@mixin font-increment($initial-font-size, $final-font-size) {
    $plus: round(( $final-font-size - $initial-font-size)/3);
    font-size: $initial-font-size;
    @content;
    @include min-screen($tp) {
        font-size: $initial-font-size + $plus;
    }
    @include min-screen($tl) {
        font-size: $initial-font-size + $plus*2;
    }
    @include min-screen($d) {
        font-size: $final-font-size;
    }
}