// General
* {
    -webkit-font-smoothing: antialiased;
    /* No bold in Mac. */
    box-sizing: border-box;
    font-weight: normal;
}

body {
    font-family: $font_regular;
    background-color: $color_dark-blue;
}
  
h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
a {
    color: inherit;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

input {
    border: none;
}