.site-header {
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 15px 0;
    @include min-screen($tl) {
        padding: 25px 0;
    }
    
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 100%;
    }

    &__logo {
        width: 134px;
        margin: 0 auto;
        @include min-screen($tl) {
            width: 190px;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}