/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@font-face {
  font-family: "OpenSans Regular";
  src: url("../fonts/opensans-regular.woff2") format("woff2"), url("../fonts/opensans-regular.woff") format("woff");
  font-weight: -weight;
  font-style: -style;
  font-display: fallback;
}
@font-face {
  font-family: "OpenSans Bold";
  src: url("../fonts/opensans-bold.woff2") format("woff2"), url("../fonts/opensans-bold.woff") format("woff");
  font-weight: "bold";
  font-style: -style;
  font-display: fallback;
}
* {
  -webkit-font-smoothing: antialiased;
  /* No bold in Mac. */
  box-sizing: border-box;
  font-weight: normal;
}

body {
  font-family: "OpenSans Regular";
  background-color: #003782;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

input {
  border: none;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.icon {
  display: inline-block;
  line-height: 0.8;
}
.icon > svg {
  display: inline-block;
  font-size: inherit;
  width: 1em;
  height: 1em;
  overflow: visible;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.site-header {
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 15px 0;
}
@media only screen and (min-width: 830px) {
  .site-header {
    padding: 25px 0;
  }
}
.site-header__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.site-header__logo {
  width: 134px;
  margin: 0 auto;
}
@media only screen and (min-width: 830px) {
  .site-header__logo {
    width: 190px;
  }
}
.site-header__logo img {
  width: 100%;
  height: auto;
  display: block;
}

.page {
  background-color: #fff;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 1150px) {
  .page {
    background-image: url("../images/bg-generic.jpg");
  }
}
@media only screen and (min-width: 1150px) {
  .page_location {
    background-image: url("../images/bg-generic-2.jpg");
  }
}
.page__container {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 79vh;
  min-height: calc(100vh - 122px);
}
.page__content {
  text-align: center;
}
@media only screen and (min-width: 1150px) {
  .page__content {
    margin-right: 50%;
    transform: translateX(-40px);
  }
}
@media only screen and (min-width: 1150px) {
  .page_location .page__content {
    margin-right: 40%;
    transform: translateX(-40px);
  }
}
.page__title {
  color: #003782;
  font-family: "OpenSans Bold";
  font-size: 18px;
}
@media only screen and (min-width: 830px) {
  .page__title {
    font-size: 38px;
  }
}
.page__logo1 {
  display: block;
  margin: 10px auto 15px;
  overflow: hidden;
  text-indent: -99em;
  width: 232px;
  height: 71px;
  background-image: url("../images/rrlogo.svg");
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateX(10px);
}
@media only screen and (min-width: 830px) {
  .page__logo1 {
    margin: 15px auto 35px;
    width: 388px;
    height: 119px;
    transform: translateX(18px);
  }
}
.page__logo3 {
  display: block;
  margin: 10px auto 15px;
  overflow: hidden;
  text-indent: -99em;
  width: 267px;
  height: 127px;
  background-image: url("../images/atlantic-logo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateX(10px);
}
@media only screen and (min-width: 830px) {
  .page__logo3 {
    margin: 15px auto 35px;
    transform: translateX(18px);
  }
}
.page__logo2 {
  display: block;
  margin: 10px auto 40px;
  overflow: hidden;
  text-indent: -99em;
  width: 247px;
  height: 63px;
  background-image: url("../images/logo-freeway-insurance.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 830px) {
  .page__logo2 {
    margin: 15px auto 35px;
    width: 429px;
    height: 110px;
  }
}
.page__text {
  margin-bottom: 10px;
  font-size: 16px;
}
@media only screen and (min-width: 830px) {
  .page__text {
    font-size: 20px;
    margin-bottom: 35px;
  }
}
.page__text p {
  margin: 0 auto 10px;
  max-width: 688px;
}

.bold-text {
  font-family: "OpenSans Bold";
}
.bold-text_blue {
  color: #0055ad;
}

.features {
  margin: 20px auto 30px;
  max-width: 480px;
}
@media only screen and (min-width: 830px) {
  .features {
    margin-top: 48px;
  }
}
.features__list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.features__icon {
  width: 70px;
  height: 70px;
  border: 5px solid #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border-radius: 50%;
  margin: 0 auto;
}
@media only screen and (min-width: 830px) {
  .features__icon {
    width: 80px;
    height: 80px;
  }
}
.features__icon_orange {
  background-color: #f39200;
}
.features__icon_skyblue {
  background-color: #00a0c8;
}
.features__icon_green {
  background-color: #3ead00;
}
.features__text {
  font-size: 12px;
  margin-top: 15px;
  max-width: 134px;
}
@media only screen and (min-width: 830px) {
  .features__text {
    font-size: 16px;
  }
}
.features__text strong {
  display: block;
  font-family: "OpenSans Bold";
}

.site-footer {
  background-color: #003782;
  padding: 20px 0;
}
@media only screen and (min-width: 830px) {
  .site-footer {
    padding: 25px 0;
  }
}
.site-footer__text {
  text-align: center;
  font-size: 14px;
  color: #fff;
  margin-bottom: 18px;
}
@media only screen and (min-width: 830px) {
  .site-footer__text {
    font-size: 18px;
    margin-bottom: 12px;
  }
}
.site-footer__text:last-child {
  margin-bottom: 0;
}
.site-footer__text p {
  margin: 0;
}